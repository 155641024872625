import {
    Box,
    HStack, 
    Text,
    Flex
} from '@chakra-ui/react'
import React, { useState } from 'react'
import NavLinks from './NavLinks';
import { IoIosMenu } from "react-icons/io";
import 'react-toastify/dist/ReactToastify.css'; 
import MobileNav from './MobileNav';
import { ToastContainer } from 'react-toastify'; 
import { Link } from 'react-router-dom';
export default function NavBar() {

    // const { isOpen, onOpen, onClose } = useDisclosure();
    // const [searchEnabled, setsearchEnabled] = useState(false);
    // const { carts } = useCarts();

    // const [searchText, setSearchText] = useState("");
    const [openMenu, setopenMenu] = useState("")

    return (
        <>
            <Box
                bg='#054E47'
                position='sticky'
                top='0'
                px={4}
                zIndex='2' m={0}>
                <Flex
                    h={20}
                    maxW='100vw'
                    display={['none', 'none', 'flex', 'flex']} alignItems={'center'} justifyContent={'space-between'}>
                    <HStack
                        w='100%'
                        spacing={5}
                        alignItems={'center'} >

                        <HStack
                            as={'nav'}
                            w='100%'
                            alignItems={'center'} justifyContent={'center'} spacing={2} display={{ base: 'none', md: 'flex' }}>
                            <NavLinks />
                        </HStack>

                    </HStack>
                </Flex>
                <Flex h={20}
                    maxW='100vw'
                    display={['flex', 'flex', 'none', 'none']} alignItems={'center'} justifyContent={'space-between'}>
                    <IoIosMenu
                        color='white'
                        size={30}
                        onClick={() => setopenMenu(true)} />
                    <Flex
                        as={Link}
                        to='/'
                        justifyContent={'center'}
                        alignItems={'center'}
                        p={2}
                        pl={20}
                        mr='2'
                    >
                        <img
                            src={'/logo.png'}
                            alt='Logo'
                        /></Flex>
                    <HStack
                        as={Link}
                        to='/cart'
                        minW='80px'
                        spacing={1}
                        justifyContent='flex-end'
                        alignItems={'center'}
                    >
                        {/* <IoBagOutline color='#fefefe' size='28px' /> */}
                        <img src='/shop-bag-white.png' width='26' />
                        <Flex
                            borderRadius={'50%'}
                            // bg={'#ECE7E3'}
                            bg={'#fefefe'}
                            color={'#966754'}
                            w={'22px'}
                            h={'22px'}
                            justifyContent={'center'}
                            alignItems='center'
                        >
                            <Text fontWeight='700'>1</Text>
                        </Flex>
                    </HStack>

                </Flex>
                {/* {isOpen ? (
                    <MobileNav />
                ) : null} */}
            </Box >

            {
                openMenu ? <MobileNav setopenMenu={setopenMenu} /> : null
            }
            <ToastContainer />
        </>
    )
}
