import { Box, Flex, HStack, Image, SimpleGrid, Text, VStack, Button, Container } from '@chakra-ui/react'
import React from 'react'
import PlaceSale from '../common/PlaceSale'
import OutlinedButton from '../common/OutlinedButton'
import { FaAngleDown } from "react-icons/fa6";
// import ProductCard from '../common/ProductCard';
import HomeRowCard from '../common/HomeRowCard';

const Festival = () => {
    const festival_data = [
        {
            imgUrl: '/home_4_1.png',
            Price: 19000,
            name: 'lorum dollar tshirt'
        },
        {
            imgUrl: '/home_4_2.png',
            Price: 19000,
            name: 'lorum dollar tshirt'
        },
        {
            imgUrl: '/home_4_3.png',
            Price: 19000,
            name: 'lorum dollar tshirt'
        },
        {
            imgUrl: '/home_4_4.png',
            Price: 19000,
            name: 'lorum dollar tshirt'
        },
        {
            imgUrl: '/home_4_5.png',
            Price: 19000,
            name: 'lorum dollar tshirt'
        },
        {
            imgUrl: '/home_4_1.png',
            Price: 19000,
            name: 'lorum dollar tshirt'
        },
        {
            imgUrl: '/home_4_4.png',
            Price: 19000,
            name: 'lorum dollar tshirt'
        },
        {
            imgUrl: '/home_row3_1.png',
            Price: 19000,
            name: 'lorum dollar tshirt'
        },
        {
            imgUrl: '/home_row3_2.png',
            Price: 19000,
            name: 'lorum dollar tshirt'
        },
        {
            imgUrl: '/home_row3_3.png',
            Price: 19000,
            name: 'lorum dollar tshirt'
        },
        {
            imgUrl: '/home_row2_1.png',
            Price: 19000,
            name: 'lorum dollar tshirt'
        },
        {
            imgUrl: '/home_row2_2.png',
            Price: 19000,
            name: 'lorum dollar tshirt'
        },
        {
            imgUrl: '/home_row2_3.png',
            Price: 19000,
            name: 'lorum dollar tshirt'
        },
        {
            imgUrl: '/home_row_2_4.png',
            Price: 19000,
            name: 'lorum dollar tshirt'
        },
        {
            imgUrl: '/home_4_2.png',
            Price: 19000,
            name: 'lorum dollar tshirt'
        },
        {
            imgUrl: '/home_4_4.png',
            Price: 19000,
            name: 'lorum dollar tshirt'
        }
    ]
    return (
        <>

            <Flex
                flexDirection={'column'}
                justifyContent={'space-between'} alignItems={'center'}
                bg={'#FFFFFF'}
            >
                <Box
                    backgroundImage='/spring_banner.png'
                    maxH='75vh'
                    height='70vh'
                    width='100%'
                    backgroundSize='cover'
                    backgroundPosition='center'
                    backgroundRepeat='no-repeat'
                    display={['none', 'none', 'flex', 'flex']}
                />
                <Container maxW='9xl' py={['4', '6', '8', '12']}
                >
                    <VStack spacing={4} w={'100%'}
                    >
                        <Text
                            fontSize={'x-large'}
                            fontWeight={800}
                            w={'100%'}
                            textAlign={'start'}
                            pl={10}
                            display={['none', 'none', 'flex', 'flex']}
                        >2024 | SPRING</Text>
                        <HStack w={'100%'}
                            py={[1, 2, 4, 4]}
                            px={[6]}
                            justifyContent={'space-between'}
                        >
                            <HStack spacing={5}>
                                <Image src='./filter_icon.png' alt='' />
                                <Text fontSize={'lg'}>Filter</Text>
                                <Text fontSize={'lg'}
                                    borderLeft={'2px solid #9F9F9F'}
                                    pl={4}
                                    textAlign={'start'}
                                    display={['none', 'none', 'flex', 'flex']}
                                >Showing 1–16 of 32 results</Text>
                            </HStack>
                            <HStack spacing={5}>
                                <Text
                                    fontSize={'lg'}
                                    display={['none', 'none', 'flex', 'flex']}
                                >Show</Text>
                                <Box
                                    p={4}
                                    justifyContent={'center'}
                                    border={'1px solid black'}
                                    display={['none', 'none', 'flex', 'flex']}
                                >12</Box>
                                <Text
                                    fontSize={'lg'}
                                    display={['none', 'none', 'flex', 'flex']}>Sort by</Text>
                                <OutlinedButton
                                    rounded='10'
                                    type='submit'
                                    width='100px'
                                    color={'#000000'}
                                    border={'1px solid #000000'}
                                    p='0'
                                    px='4'
                                    display={['flex', 'flex', 'none', 'none']}
                                    gap={3}
                                    _hover={
                                        {
                                            bg: 'none'
                                        }
                                    }>
                                    <Text>Sort by</Text>
                                    <FaAngleDown size={40} />
                                </OutlinedButton>
                                <OutlinedButton
                                    rounded='0'
                                    type='submit'
                                    width='150px'
                                    color={'#000000'}
                                    border={'1px solid #000000'}
                                    _hover={
                                        {
                                            bg: 'none'
                                        }
                                    }
                                    display={['none', 'none', 'flex', 'flex']}
                                >
                                    Default</OutlinedButton>
                            </HStack>
                        </HStack>
                        <SimpleGrid columns={[2, 2, 3, 4]} w='100%' spacing={4}>
                            {
                                festival_data.map((item, index) => {
                                    return <HomeRowCard item={item} key={index} />
                                })
                            }
                        </SimpleGrid>
                        <HStack
                            spacing={6}
                            display={['none', 'none', 'flex', 'flex']}
                            justifyContent={'center'}
                            alignItems={'center'}
                            my={5}
                        >
                            <Button
                                fontSize={'lg'}
                                bg='#F4F1EF'
                                _hover={
                                    { bg: '#F4F1EF' }
                                }
                                rounded={3}
                            >
                                1
                            </Button>
                            <Button
                                fontSize={'lg'}
                                bg='#F4F1EF'
                                _hover={
                                    { bg: '#F4F1EF' }
                                }
                                rounded={3}
                            >
                                2
                            </Button>
                            <Button
                                fontSize={'lg'}
                                bg='#F4F1EF'

                                _hover={
                                    { bg: '#F4F1EF' }
                                }
                                rounded={3}
                            >
                                3
                            </Button>
                            <Button
                                fontSize={'lg'}
                                bg='#F4F1EF'

                                _hover={
                                    { bg: '#F4F1EF' }
                                }
                                rounded={3}
                            >
                                Next
                            </Button>
                        </HStack>
                    </VStack>
                </Container>
                <PlaceSale />
            </Flex>
        </>
    )
}

export default Festival