import React, { useState, useEffect } from 'react'
import {
    Text, Image, VStack, Button, HStack,
    // useBreakpointValue,
    SimpleGrid, Box, Flex,
} from '@chakra-ui/react';
// import { useParams } from 'react-router-dom';
// import { url } from '../../../config';
// import Slider from 'react-slick/lib/slider';
import ProductCard from '../../common/ProductCard';
// import { useCarts } from '../../../providers/CartProvider';
// import { useAuth } from '../../../providers/AuthProvider';
// import { useNavigate } from 'react-router-dom';
// import { toast } from 'react-toastify';
import PlaceSale from '../../common/PlaceSale';
import { FaStar } from 'react-icons/fa';
// import HomeRowCard from '../../common/HomeRowCard';
// import axios from 'axios';
import OutlinedButton from '../../common/OutlinedButton'
import { Link } from 'react-router-dom';
import Description from './Description';
import AdditinalInfo from './AdditinalInfo';
import Reviews from './Reviews';


export default function ProductDetails() {
    // const params = useParams();
    // const [product, setProduct] = useState(null);
    // const [images, setImages] = useState([]);
    // const arrowShow = useBreakpointValue({ base: false, lg: true })
    // var { carts, addCarts } = useCarts();
    // var { logged } = useAuth();

    // const nav = useNavigate();



    const productInfocategory = [
        {
            category: "Description",
            component: <Description />
        },
        {
            category: "Additional Information",
            component: <AdditinalInfo />
        },
        {
            category: "Reviews",
            component: <Reviews />
        }
    ]
    const [selected, setselected] = useState('Description');

    const [filterproductInfocategory, setfilterproductInfocategory] = useState([productInfocategory[0]])
    useEffect(() => {
        setfilterproductInfocategory(productInfocategory.find((p) => p.category === selected));
    }, [selected]);

    // const settings = {
    //     dots: true,
    //     arrows: arrowShow,
    //     fade: true,
    //     // infinite: true,
    //     autoplay: true,
    //     speed: 500,
    //     autoplaySpeed: 3000,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    // };

    // const { categories } = useCategories();
    // const [categoryProducts, setcategoryProducts] = useState([]);
    // const [uniqueColorVariants, setUniqueColorVariants] = useState([]);
    // const [uniqueSizeVariants, setUniqueSizeVariants] = useState([]);


    // async function getProductDetails() {
    //     try {
    //         var { data } = await axios.get(`${url}/product/${params.slug}`);
    //         setProduct(data.data[0]);
    //         setcategoryProducts(data.category_products);

    //         let uniqueColors = data.other_variants.filter((product, index, self) =>
    //             index === self.findIndex((p) => p.color === product.color)
    //         );
    //         let uniqueSizes = data.other_variants.filter((product, index, self) =>
    //             index === self.findIndex((p) => p.size === product.size)
    //         );

    //         setUniqueColorVariants(uniqueColors);
    //         setUniqueSizeVariants(uniqueSizes);

    //         let imgs = [];
    //         if (data.data[0].product_variants['image1'] != null) {
    //             // console.log(data.data[0].product_variants['image1']);
    //             imgs.push(data.data[0].product_variants['image1']);
    //         }
    //         if (data.data[0].product_variants['image2'] != null) {
    //             // console.log(data.data[0].product_variants['image2']);
    //             imgs.push(data.data[0].product_variants['image2']);
    //         }
    //         if (data.data[0].product_variants['image3'] != null) {
    //             // console.log(data.data[0].product_variants['image3']);
    //             imgs.push(data.data[0].product_variants['image3']);
    //         }
    //         // console.log(imgs);
    //         setImages(imgs);
    //     } catch (error) {
    //         console.error(error);
    //     }
    // }

    // useEffect(() => {
    //     console.log(params)
    //     if (params.slug !== undefined) {
    //         getProductDetails();
    //     }
    // }, [window.location.pathname]) // eslint-disable-line react-hooks/exhaustive-deps

    // if (product == null) {
    //     return <div>Loading...</div>
    // }


    // New code 

    const home_row2_data = [
        {
            imgUrl: '/home_row2_1.png',
            Price: 2000,
            name: 'lorum dollar tshirt'
        },
        {
            imgUrl: '/home_row2_2.png',
            Price: 19000,
            name: 'lorum dollar tshirt'
        },
        {
            imgUrl: '/home_row2_3.png',
            Price: 54000,
            name: 'lorum dollar tshirt'
        },
        {
            imgUrl: '/home_row2_1.png',
            Price: 34000,
            name: 'lorum dollar tshirt'
        },
        {
            imgUrl: '/home_row_2_4.png',
            Price: 23999,
            name: 'lorum dollar tshirt'
        }
    ]



    return (
        <>
            <Flex
                minH='100vh'
                flexDirection={'column'}
                justifyContent={'space-between'}
                alignItems={'center'}
                bg={'#FFFFFF'}
                display={['none', 'none', 'flex', 'flex']}
            >
                <VStack h={'80vh'} w={'90%'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    my={5}
                >
                    <Text fontSize={'md'} color={'#000000'} h={'8%'} w={'100%'}
                        textAlign={'left'}
                    >
                        Home &gt; Lorem ipsum 1 &gt; Lorem ipsum PDP
                    </Text>
                    <Flex h={'90%'} w={'100%'}
                        justifyContent={'space-between'}
                        alignItems={'flex-start'}
                        gap={2}
                    >
                        <VStack w={'8%'} h={'100%'}
                            justifyContent={'flex-start'}
                            alignItems={'flex-start'}
                            gap={4}
                        >
                            <Image src='/home_row2_1.png' alt='' h={'16%'} w={'90%'} borderRadius={'10px'} />
                            <Image src='/home_row2_1.png' alt='' h={'16%'} w={'90%'} borderRadius={'10px'} />
                            <Image src='/home_row2_1.png' alt='' h={'16%'} w={'90%'} borderRadius={'10px'} />
                            <Image src='/home_row2_1.png' alt='' h={'16%'} w={'90%'} borderRadius={'10px'} />
                        </VStack>
                        <Image src='/home_row2_1.png' alt='' w={'32%'} h={'90%'} borderRadius={'20px'} />
                        <VStack w={'55%'} h={'100%'}
                            justifyContent={'flex-start'}
                            alignItems={'flex-start'}
                            gap={2}
                        >
                            <Text fontSize={'xx-large'}
                                color={'#000000'}
                            >LOREM IPSUM</Text>
                            <Text fontSize={'xl'}
                                color={'#000000'}>Rs. 250,000.00</Text>
                            <Flex h={'5%'}
                                w={'50%'}
                                justifyContent={'flex-start'}
                                alignItems={'flex-start'}
                            >
                                <HStack h={'100%'}
                                    w={'50%'}
                                    justifyContent={'flex-start'}
                                    alignItems={'center'}
                                    borderRight={'1px solid #9F9F9F'}
                                >
                                    <FaStar color='#FFDA5B' />
                                    <FaStar color='#FFDA5B' />
                                    <FaStar color='#FFDA5B' />
                                    <FaStar color='#FFDA5B' />
                                    <FaStar color='#FFDA5B' />
                                </HStack>
                                <Text
                                    h={'100%'}
                                    w={'50%'}
                                    textAlign={'center'}
                                    fontSize={'md'}
                                    color={'#9F9F9F'}
                                >5 Customer Review</Text>
                            </Flex>
                            <Text fontSize={'md'}
                                color={'#000000'}>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </Text>
                            <Text fontSize={'md'}
                                color={'#000000'}>
                                Size
                            </Text>
                            <HStack w='50%'>
                                <Button
                                    fontSize={'md'}
                                    color={'#000000'}
                                    bg={'#FAF4F4'}
                                    _hover={
                                        {
                                            bg: '#FAF4F4'
                                        }
                                    }
                                    w={'12%'}>S</Button>
                                <Button
                                    fontSize={'md'}
                                    color={'#000000'}
                                    bg={'#FAF4F4'}
                                    _hover={
                                        {
                                            bg: '#FAF4F4'
                                        }
                                    }
                                    w={'12%'}>M</Button>
                                <Button
                                    fontSize={'md'}
                                    color={'#000000'}
                                    bg={'#FAF4F4'}
                                    _hover={
                                        {
                                            bg: '#FAF4F4'
                                        }
                                    }
                                    w={'12%'}>L</Button>
                            </HStack>
                            <Text fontSize={'md'}
                                color={'#000000'}>
                                Color
                            </Text>
                            <HStack w='50%' gap={2}>
                                <Box borderRadius={'50%'}
                                    h={18}
                                    w={18}
                                    bg={'#816DFA'}
                                >
                                </Box>
                                <Box borderRadius={'50%'}
                                    h={18}
                                    w={18}
                                    bg={'#000000'}
                                >
                                </Box>
                                <Box borderRadius={'50%'}
                                    h={18}
                                    w={18}
                                    bg={'#CDBA7B'}
                                >
                                </Box>
                            </HStack>
                            <Flex w={'60%'}
                                h={'20%'}
                                gap={5}
                                justifyContent={'flex-start'} alignItems={'center'}>
                                <Box w={'30%'} display={'flex'}
                                    justifyContent={'space-between'}
                                    alignItems={'center'}
                                    border={'1px solid #9F9F9F'}
                                    borderRadius={'md'}
                                >
                                    <Button
                                        bg={'none'}
                                        color={'#000000'}
                                        fontSize={'md'}
                                        _hover={
                                            { bg: 'none' }
                                        }
                                    >+</Button>
                                    <Text w={'50%'}
                                        fontSize={'md'}
                                        color={'#000000'}
                                        h={'100%'}
                                        textAlign={'center'}
                                    >1</Text>
                                    <Button
                                        bg={'none'}
                                        color={'#000000'}
                                        fontSize={'md'}
                                        _hover={
                                            { bg: 'none' }
                                        }
                                    >-</Button>
                                </Box>
                                <Button w={'40%'}
                                    bg={'none'}
                                    color={'#054E47'}
                                    border={'1px solid #000000'}
                                    borderRadius={0}
                                    fontSize={'md'}
                                    _hover={
                                        { bg: 'none' }
                                    }
                                >
                                    <Link to={'/cart'}>
                                        ADD TO CART
                                    </Link>
                                </Button>
                            </Flex>
                        </VStack>
                    </Flex>
                </VStack>
                <VStack h={'80vh'} w={'100%'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    mt={2}
                    mb={50}
                    borderTop={'1px solid #D9D9D9'}
                >
                    <Flex
                        w={'50%'}
                        h={'20%'}
                        justifyContent={'space-evenly'} alignItems={'center'}>

                        {
                            productInfocategory.map((p, index) => {
                                return <VStack
                                    justifyContent={'space-between'}
                                    alignItems={'center'}
                                    key={index}
                                    onClick={() => setselected(p.category)}
                                >
                                    <Text fontSize={'x-large'}
                                        color={'#000000'}

                                        _hover={
                                            { cursor: "pointer" }
                                        }
                                    >
                                        {p.category}
                                    </Text>
                                    <Box
                                        h={'2px'}
                                        w={'100%'}
                                        rounded={20}
                                        m={0}
                                        p={0}
                                        bg={selected === p.category ? "black" : "none"}
                                    /></VStack>
                            })
                        }
                    </Flex>
                    {
                        filterproductInfocategory?.component && (
                            <>
                                {filterproductInfocategory.component}
                            </>
                        )
                    }
                </VStack>
                <Text textAlign={'center'} fontSize={'xx-large'}
                    color={'#966754'}
                >
                    Related Products
                </Text>
                <SimpleGrid columns={[2, 2, 3, 5]}
                    my={4}
                    w='100%' spacing={4}>
                    {
                        home_row2_data.map((item, index) => {
                            return <ProductCard item={item} key={index} />
                        })
                    }
                </SimpleGrid>
                <PlaceSale />
            </Flex>
            <Flex
                display={['flex', 'flex', 'none', 'none']}
                flexDirection={'column'}
                justifyContent={'space-between'}
                alignItems={'center'}
                bg={'#FFFFFF'}
                minH={'80vh'}
                gap={2}
            >
                <Box
                    h={'65vh'} w={'100%'}
                    bgImage={'/home_row2_1.png'}
                    bgSize={'cover'}
                >
                </Box>
                <VStack spacing={3} my={4} w={'100%'} px={'5'}>
                    <Text fontSize={'lg'} w={'100%'} >
                        Loreum Ipusm shirt
                    </Text>
                    <Text fontSize={'lg'} w={'100%'} fontWeight={800} >
                        ₹12000
                    </Text>
                    <Text fontSize={'md'} w={'100%'}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam arcu mauris, scelerisque eu mauris id, pretium pulvinar sapien.
                    </Text>
                    <HStack justifyContent={'space-between'} w={'100%'}>
                        <Text fontSize={'md'}>Size</Text>
                        <Text fontSize={'md'} textAlign={'end'}>
                            Size chart
                        </Text>
                    </HStack>
                    <HStack gap={'2'} w={'100%'}>
                        <Button
                            fontSize={'md'}
                            color={'#000000'}
                            bg={'#FAF4F4'}
                            _hover={
                                {
                                    bg: '#FAF4F4'
                                }
                            }
                            h={'30px'}
                        >-</Button>
                        <Button
                            fontSize={'md'}
                            color={'#000000'}
                            bg={'#FAF4F4'}
                            _hover={
                                {
                                    bg: '#FAF4F4'
                                }
                            }
                            h={'30px'}
                        >+</Button>
                    </HStack>
                    <Text fontSize={'md'} w={'100%'} >
                        Choose colour options
                    </Text>
                    <HStack gap={2} w={'100%'}>
                        <Image src='/home_row2_1.png' alt='' rounded={6} h={'16vh'} />
                        <Image src='/home_row2_1.png' alt='' rounded={6} h={'16vh'} />
                        <Image src='/home_row2_1.png' alt='' rounded={6} h={'16vh'} />
                    </HStack>
                    <HStack gap={2} w={'100%'}>
                        <OutlinedButton rounded='0' type='submit' width='150px' color={'#000000'}
                            bg='none'

                            border={'1px solid #000000'}
                            _hover={
                                {
                                    bg: 'none'
                                }
                            }
                            py='2'
                            fontSize='lg'
                        >
                            Add to Cart</OutlinedButton>
                        <OutlinedButton rounded='0' width='150px' color={'white'}
                            bg='#054E47' border='none'
                            _hover={
                                {
                                    bg: '#054E47'
                                }
                            }
                            py='2'
                            fontSize='lg'
                        >
                            Buy now</OutlinedButton>
                    </HStack>
                    <Text fontSize={'sm'} w={'100%'} >
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam arcu mauris, scelerisque eu
                    </Text>
                </VStack>
            </Flex>
        </>
    )

    // return (
    //     <Container maxW='7xl' my='8'>
    //         <VStack w='100%' spacing={{ base: '6', md: '12' }}>
    //             <Stack w='100%' direction={{ base: 'column', md: 'row' }} spacing={{ base: '8', md: '4' }}>
    //                 <HStack w={{ base: '100%', md: '50%' }}>

    //                     <Box w='100%'>
    //                         {images.length > 0 ? (
    //                             <Slider {...settings}>
    //                                 {images.map((url, index) => (
    //                                     <AspectRatio key={index} ratio={1 / 1}>
    //                                         <Box
    //                                             aspectRatio={1 / 1}
    //                                             w='100%'
    //                                             backgroundPosition="center center"
    //                                             backgroundRepeat="no-repeat"
    //                                             backgroundSize="contain"
    //                                             backgroundImage={`url(${url})`}
    //                                         />
    //                                     </AspectRatio>
    //                                 ))}
    //                             </Slider>
    //                         ) : <></>}
    //                     </Box>

    //                 </HStack>
    //                 <VStack alignItems='start' spacing='4' w={{ base: '100%', md: '50%' }}>
    //                     <HStack w='100%' alignItems='start' justifyContent='start' spacing='1'>
    //                         <Text fontWeight='800' fontSize='xl' display='inline-block'>{product.products.name} </Text>
    //                         {/* <Text fontWeight='800' fontSize='xl' display='inline-block'>{product.product_variants.size} - </Text>
    //                         <Text fontWeight='800' fontSize='xl' display='inline-block'>{product.product_variants.color}</Text> */}
    //                     </HStack>
    //                     <HStack justifyContent='start' w='100%' spacing='2'>
    //                         <HStack spacing='0'>
    //                             <Image src='/rupee_light.png' w='8px' me='2px' />
    //                             <Text as='h4' style={{ textDecoration: 'line-through' }} fontSize='md'>
    //                                 {product.product_variants.mrp}
    //                             </Text>
    //                         </HStack>

    //                         <HStack spacing='0'>
    //                             <Image src='/rupee.png' w='10px' me='2px' />
    //                             <Text as='h1' className='customfont' fontSize='lg'>
    //                                 {product.product_variants.selling_price}
    //                             </Text>
    //                         </HStack>

    //                         <Text
    //                             color='#ff5c5c'
    //                             fontSize={[12, 14, 18, 18]}>
    //                             ({Math.round(((product.product_variants.mrp - product.product_variants.selling_price) / product.product_variants.mrp) * 100)}% off)
    //                         </Text>
    //                     </HStack>

    //                     <VStack w='100%' alignItems='start'>
    //                         <HStack spacing='1'> <Text>Color : </Text>  <Text fontWeight='800'> {product.product_variants.color} </Text></HStack>
    //                         <HStack>
    //                             {uniqueColorVariants.map((ov, indx) => {
    //                                 return <VStack h='100%' justifyContent='space-between'>
    //                                     <a to={`/product/${ov.slug}`}>
    //                                         <Box
    //                                             boxSizing='content-box'
    //                                             border={product.product_variants.color_hex === ov.color_hex ? '3px solid #a8a8a8' : ''}
    //                                             key={indx}
    //                                             borderRadius='30px'
    //                                             w={product.product_variants.color_hex === ov.color_hex ? '42px' : '42px'}
    //                                             h={product.product_variants.color_hex === ov.color_hex ? '42px' : '42px'}
    //                                             bg={ov.color_hex}
    //                                         >
    //                                         </Box>
    //                                     </a>
    //                                 </VStack>
    //                             })}
    //                         </HStack>
    //                     </VStack>

    //                     <VStack w='100%' alignItems='start'>
    //                         <HStack spacing='1'>
    //                             <Text>Size : </Text>
    //                             <Text fontWeight='800'> {product.product_variants.size}</Text>
    //                         </HStack>
    //                         <HStack>
    //                             {uniqueSizeVariants.map((ov, indx) => {
    //                                 return <a href={`/product/${ov.slug}`}>
    //                                     <Box
    //                                         key={indx}
    //                                         bg={product.product_variants.size === ov.size ? '#1c1c1c' : 'transparent'}
    //                                         display='flex'
    //                                         w='50px'
    //                                         h='38px'
    //                                         border='1px solid #1c1c1c'
    //                                         borderRadius='8px'
    //                                         justifyContent='center'
    //                                         alignItems='center'
    //                                     >
    //                                         <Text
    //                                             className='customFont'
    //                                             fontWeight='800'
    //                                             alignItems='center'
    //                                             color={product.product_variants.size === ov.size ? 'white' : '#1d1d1d'}
    //                                         >{ov.size}</Text>
    //                                     </Box>
    //                                 </a>
    //                             })}
    //                         </HStack>
    //                     </VStack>

    //                     {logged ?
    //                         <>
    //                             {carts.filter((c) => c.product_id === product.products.id && c.product_variant_id === product.product_variants.id).length > 0 ? <>
    //                                 <Button bg="#1c1c1c" _hover={{ bg: '#1d1d1d' }} color="#fefefe" py='6' borderRadius='0' onClick={() => addCarts(product.products.id, product.product_variants.id, 0)}>
    //                                     Remove From Cart
    //                                 </Button>
    //                             </> : <>
    //                                 <Button bg="#1c1c1c" _hover={{ bg: '#1d1d1d' }} color="#fefefe" py='6' borderRadius='0' onClick={() => addCarts(product.products.id, product.product_variants.id, 1)}>
    //                                     Add to Cart
    //                                 </Button>
    //                             </>}
    //                         </>
    //                         :
    //                         <Button bg="#1c1c1c" _hover={{ bg: '#1d1d1d' }} color="#fefefe" py='6' borderRadius='0' onClick={() => { toast.warning("Please login to continue !"); nav('/login') }}>
    //                             Add to Cart
    //                         </Button>
    //                     }


    //                     <VStack alignItems='start'>
    //                         <Text className='customFont' fontSize='2xl' fontWeight='800'>Product Highlights</Text>
    //                         <Box px='4'>
    //                             <ul>
    //                                 <li>Quality Craftsmanship</li>
    //                                 <li>Soft and Breathable Fabric</li>
    //                                 <li>Versatile Color Options</li>
    //                                 <li>Morden fit</li>
    //                                 <li>Sustainability</li>
    //                             </ul>
    //                         </Box>

    //                     </VStack>
    //                 </VStack>
    //             </Stack>

    //             <SimpleGrid columns={[1, 1, 1, 3]} w='100%' spacing={{ base: '4', md: '8' }} px='0'>
    //                 <Card w='100%' shadow='sm' p='4'>
    //                     <VStack w='100%' alignItems='start'>
    //                         <Text as='h2' className='customFont' fontSize='lg'>Care Details</Text>
    //                         <Text fontSize='md'>{product.products.care_details}</Text>
    //                     </VStack>
    //                 </Card>
    //                 <Card w='100%' shadow='sm' p='4'>
    //                     <VStack w='100%' alignItems='start'>
    //                         <Text as='h2' className='customFont' fontSize='lg'>Product Highlights</Text>
    //                         <Text fontSize='md'>{product.products.product_highlights}</Text>
    //                     </VStack>
    //                 </Card>
    //                 <Card w='100%' shadow='sm' p='4'>
    //                     <VStack w='100%' alignItems='start'>
    //                         <Text as='h2' className='customFont' fontSize='lg'>Return Policy</Text>
    //                         <Text fontSize='md'>{product.products.return_policy}</Text>
    //                     </VStack>
    //                 </Card>
    //             </SimpleGrid>

    //             <Text alignItems='start' w='100%' className='customFont' as='h1' fontSize='2xl'>More Products in this Category</Text>

    //             <SimpleGrid w='100%' columns={[2, 2, 3, 4]} spacing={[4, 4, 6, 8]}>
    //                 {categoryProducts.map((p, index) => {
    //                     return <ProductCard key={index} product={p} />
    //                 })}
    //             </SimpleGrid>

    //             <Text alignItems='start' w='100%' className='customFont' as='h1' fontSize='2xl'>Product Reviews</Text>

    //             <SimpleGrid w='100%' columns={[2, 2, 3, 4]} spacing={[4, 4, 6, 8]}>
    //                 <Text>No reviews yet</Text>
    //             </SimpleGrid>

    //         </VStack >
    //     </Container >
    // )
}
