import {
    Flex,
    Textarea,
    VStack,
    Text,
    FormLabel,
    Input,
    Box,
    HStack,
    FormControl,
    RadioGroup,
    Radio
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useState } from 'react';
import { FaClock, FaLocationDot } from 'react-icons/fa6';
import { useContacts } from '../../providers/ContactProvider';
import { toast } from 'react-toastify';
import { MdOutlineAccessTimeFilled } from 'react-icons/md';
import { FaPhoneAlt } from 'react-icons/fa';
import OutlinedButton from '../common/OutlinedButton';
import PlaceSale from '../common/PlaceSale';
import { BiSolidPhoneCall } from "react-icons/bi";
import { IoMailSharp } from "react-icons/io5";

const Contact = () => {
    const { addContact } = useContacts();

    const [error, setError] = useState();

    const [info, setinfo] = useState(null);
    const [sub, setsub] = useState('general')

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            phone: "",
            message: "",
        },
        onSubmit: async (values) => {
            setinfo("Adding...");
            var res = await addContact(values.name, values.email, values.phone, values.message);
            setinfo(null);
            if (res === "err") {
                setError("Error Adding Contact !");
            } else {
                values.name = "";
                values.email = "";
                values.phone = "";
                values.message = "";
                toast.success("Thanks for your message!. We will get back to you soon");
            }
        }

    });

    return (
        <>
            <Flex
                minH='100vh'
                width={'100vw'}
                flexDirection={'column'}
                justifyContent={'space-between'} alignItems={'center'}
                bg={'#FFFFFF'}
                display={['none', 'none', 'flex', 'flex']}
            >
                <Box
                    bgImage='./contact_banner.png'
                    bgSize='cover'
                    bgPosition='center'
                    w='100%'
                    h={'45vh'} />

                <VStack
                    w={'85%'}
                    my={10}
                    justifyContent={'space-between'}
                    alignItems={'center'}>
                    <VStack
                        w={'70%'}
                        justifyContent={'center'}
                        gap={3}
                        alignItems={'center'}
                    >
                        <Text
                            fontSize={'3xl'}
                            fontWeight={700}
                            textAlign={'center'}
                            color={'#000000'}
                        >
                            Get In Touch With Us
                        </Text>
                        <Text
                            fontSize={'lg'}
                            textAlign={'center'}
                            color={'#000000'}
                        >
                            For More Information About Our Product & Services. Please Feel Free To Drop Us An Email. Our Staff Always Be There To Help You Out. Do Not Hesitate!
                        </Text>
                    </VStack>
                    <HStack
                        py={5}
                        w='5xl'
                        justifyContent={'space-between'}
                        alignItems={'start'}
                        spacing={10}

                    >
                        <VStack
                            w='100%'
                            justifyContent={'start'}
                            alignItems={'start'}
                        >
                            <VStack
                                justifyContent={'start'}
                                w={'100%'}
                                alignItems={'start'}
                                spacing='8'
                            >
                                <Flex
                                    justifyContent={'start'}
                                    width={'100%'}
                                    gap={4}
                                >
                                    <FaLocationDot
                                        color='#000000'
                                        size={30} />
                                    <VStack
                                        justifyContent={'flex-start'}
                                        alignItems={'flex-start'}>
                                        <Text
                                            color={'#000000'}
                                            fontSize={'2xl'}
                                            fontWeight={600}>
                                            Address
                                        </Text>
                                        <Text
                                            fontSize={'lg'}
                                            color={'#000000'}>
                                            236 5th SE Avenue, New York NY10000, United States
                                        </Text>
                                    </VStack>

                                </Flex>
                                <Flex
                                    gap={4}
                                    justifyContent={'start'}
                                    w='100%'
                                >
                                    <FaPhoneAlt
                                        color='#000000'
                                        size={30} />
                                    <VStack
                                        justifyContent={'flex-start'}
                                        alignItems={'flex-start'}>
                                        <Text
                                            color={'#000000'}
                                            fontSize={'2xl'}
                                            fontWeight={600}>
                                            Phone
                                        </Text>
                                        <Text
                                            fontSize={'lg'}
                                            color={'#000000'}>
                                            Mobile: +(84) 546-6789 Hotline: +(84) 456-6789
                                        </Text>
                                    </VStack>

                                </Flex>
                                <Flex
                                    gap={4}>
                                    <FaClock
                                        color='#000000'
                                        size={30} />
                                    <VStack
                                        justifyContent={'flex-start'}
                                        alignItems={'flex-start'}>
                                        <Text
                                            fontSize={'2xl'}
                                            color={'#000000'}
                                            fontWeight={600}>
                                            Address
                                        </Text>
                                        <Text
                                            fontSize={'lg'}
                                            color={'#000000'}>
                                            236 5th SE Avenue, New York NY10000, United States
                                        </Text>
                                    </VStack>

                                </Flex>
                            </VStack>

                            <Flex
                                maxH={'300px'}
                                w={'100%'}
                                bg={'#D9D9D9'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                my={2}
                            >
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d243207.75285017327!2d83.09777638978257!3d17.73893044894249!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a39431389e6973f%3A0x92d9c20395498468!2sVisakhapatnam%2C%20Andhra%20Pradesh!5e0!3m2!1sen!2sin!4v1727267811014!5m2!1sen!2sin"
                                    height='300'
                                    width={'100%'}
                                    style={{ border: 0 }}
                                    allowfullscreen=""
                                    loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade">
                                </iframe>

                            </Flex>
                        </VStack>
                        <VStack
                            w='100%'
                            justifyContent={'center'}
                            spacing={4}
                            alignItems={'flex-start'}
                            p={4}
                        >
                            <FormControl>
                                <FormLabel
                                    w={'100%'}
                                    justifyContent={'flex-start'}
                                    alignItems={'center'}
                                    color={'#000000'} fontSize={'xl'}
                                >
                                    Your Name
                                </FormLabel>
                                <Input
                                    type='text'
                                    fontSize={'md'}
                                    border={'1px solid #9F9F9F'}
                                    py='8'
                                    size='lg'
                                    placeholder='abc' />
                            </FormControl>

                            <FormControl>
                                <FormLabel
                                    w={'100%'}
                                    justifyContent={'flex-start'}
                                    alignItems={'center'}
                                    color={'#000000'}
                                    fontSize={'xl'}
                                >
                                    Your Email
                                </FormLabel>
                                <Input
                                    type='text'
                                    py='8'
                                    size='lg'
                                    fontSize={'md'}
                                    border={'1px solid #9F9F9F'}
                                    placeholder='abc@gmail.com'
                                />
                            </FormControl>

                            <FormControl>

                                <FormLabel
                                    w={'100%'}
                                    justifyContent={'flex-start'}
                                    alignItems={'center'}
                                    color={'#000000'}
                                    fontSize={'xl'}
                                >
                                    Subject
                                </FormLabel>
                                <Input
                                    type='text'
                                    fontSize={'md'}
                                    py='8'
                                    size='lg'
                                    border={'1px solid #9F9F9F'}
                                    placeholder='This is an optional' />
                            </FormControl>

                            <FormControl>

                                <FormLabel
                                    w={'100%'}
                                    justifyContent={'flex-start'}
                                    alignItems={'center'}
                                    color={'#000000'}
                                    fontSize={'xl'}
                                >
                                    Message
                                </FormLabel>
                                <Textarea
                                    type='text'
                                    border='1px solid #9F9F9F'
                                    h='20%'
                                    placeholder='Hi! I like to ask about'
                                    textAlign={'left'}
                                    fontSize={'md'}
                                    w={'100%'}
                                />
                            </FormControl>

                            <OutlinedButton
                                rounded='0'
                                type='submit'
                                width='150px'
                                color={'#054E47'}>SUBMIT</OutlinedButton>

                        </VStack>
                    </HStack>
                </VStack>

                <PlaceSale />

            </Flex>
            <Flex
                gap={6}
                w='100%'
                px={6}
                py={4}
                flexDirection={'column'}
                justifyContent={'space-between'}
                bg={'#FFFFFF'}
                display={['flex', 'flex', 'none', 'none']}
            >
                <Text fontSize='x-large' w={'100%'}
                    textAlign={'left'}
                >CONTACT US</Text>
                <Box
                    display='flex'
                    justifyContent='center'
                    alignItems={'center'}
                    h={'40vh'}
                    w={'100%'}
                    my={2}
                >
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d243207.75285017327!2d83.09777638978257!3d17.73893044894249!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a39431389e6973f%3A0x92d9c20395498468!2sVisakhapatnam%2C%20Andhra%20Pradesh!5e0!3m2!1sen!2sin!4v1727267811014!5m2!1sen!2sin"
                        height='100%'
                        width={'100%'}
                        style={{ border: 0 }}
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade">
                    </iframe>
                </Box>
                <HStack
                    w={'100%'}
                    justifyContent={'space-between'}>
                    <VStack
                        gap={4}
                        justifyContent={'flex-start'}
                        alignItems={'flex-start'}>
                        <BiSolidPhoneCall size={22} />
                        <Text
                            fontSize={'sm'}
                            w='100%'
                            textAlign={'left'}>
                            +1012 3456 789
                        </Text>
                    </VStack>
                    <VStack
                        gap={4}
                        justifyContent={'flex-start'}
                        alignItems={'flex-start'}>
                        <IoMailSharp size={22} />
                        <Text
                            fontSize={'sm'}
                            w='100%'
                            textAlign={'left'}>
                            demodemodemo@gmail.com
                        </Text>
                    </VStack>
                </HStack>
                <VStack
                    borderBottom={'1px solid black'}
                    pb={5}
                    gap={4}
                    mb={2}
                    w={'100%'}
                    justifyContent={'flex-start'}
                    alignItems={'flex-start'}>
                    <FaLocationDot size={22} />
                    <Text
                        fontSize={'sm'}
                        w='100%'
                        textAlign={'left'}>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                    </Text>
                </VStack>
                <FormControl>
                    <FormLabel
                        fontSize={'md'}
                        w={'100%'}>
                        First Name
                    </FormLabel>
                    <Input
                        type='text'
                        w='100%'
                        borderRadius={0}
                        border={'none'}
                        borderBottom='1px solid #E5E5E5'
                        _focusVisible={
                            {
                                border: 'none',
                                borderBottom: '1px solid #E5E5E5'
                            }
                        }
                    />
                </FormControl>
                <FormControl>
                    <FormLabel
                        fontSize={'md'}
                        w={'100%'}>
                        Last Name
                    </FormLabel>
                    <Input
                        type='text'
                        w='100%'
                        borderRadius={0}
                        border={'none'}
                        borderBottom='1px solid #E5E5E5'
                        _focusVisible={
                            {
                                border: 'none',
                                borderBottom: '1px solid #E5E5E5'
                            }
                        }
                    />
                </FormControl>
                <FormControl>
                    <FormLabel
                        fontSize={'md'}
                        w={'100%'}>
                        Email
                    </FormLabel>
                    <Input
                        type='email'
                        w='100%'
                        borderRadius={0}
                        border={'none'}
                        borderBottom='1px solid #E5E5E5'
                        _focusVisible={
                            {
                                border: 'none',
                                borderBottom: '1px solid #E5E5E5'
                            }
                        }
                    />
                </FormControl>
                <FormControl mb={2}>
                    <FormLabel
                        fontSize={'md'}
                        w={'100%'}>
                        Number
                    </FormLabel>
                    <Input
                        type='number'
                        w='100%'
                        borderRadius={0}
                        border={'none'}
                        borderBottom='1px solid #E5E5E5'
                        _focusVisible={
                            {
                                border: 'none',
                                borderBottom: '1px solid #E5E5E5'
                            }
                        }
                    />
                </FormControl>
                <Text
                    fontSize={'lg'}
                    fontWeight={800}
                    w={'100%'}
                    textAlign={'left'}
                >
                    Select Subject
                </Text>
                <RadioGroup
                    w={'100%'}
                    px={4}
                    onChange={setsub} value={sub}
                    mb={3}
                >
                    <HStack
                        gap={3}
                        w='100%'
                        justifyContent={'space-between'}>
                        <VStack
                            gap={2}>
                            <Radio
                                value="general"
                            >
                                <Text
                                    color={'#000000'}
                                    fontWeight={600}
                                    fontSize={'sm'}
                                >
                                    General Inquiry
                                </Text>
                            </Radio>
                            <Radio
                                value="OrderDetails"
                            >
                                <Text
                                    color={'#000000'}
                                    fontWeight={600}
                                    fontSize={'sm'}
                                >
                                    General Inquiry
                                </Text>
                            </Radio>
                        </VStack>
                        <VStack
                            gap={2}>
                            <Radio
                                value="codRelated"
                                color={'#000000'}
                            >
                                <Text
                                    color={'#000000'}
                                    fontWeight={600}
                                    fontSize={'sm'}
                                >
                                    General Inquiry
                                </Text>
                            </Radio>
                            <Radio
                                value="ReturnOrder"
                                color={'#000000'}
                            >
                                <Text
                                    color={'#000000'}
                                    fontWeight={600}
                                    fontSize={'sm'}
                                >
                                    General Inquiry
                                </Text>
                            </Radio>
                        </VStack>
                    </HStack>
                </RadioGroup>
                <FormControl>
                    <FormLabel
                        fontSize={'lg'}
                        fontWeight={800}
                        w={'100%'}
                    >
                        Message
                    </FormLabel>
                    <Input
                        type='text'
                        w='100%'
                        borderRadius={0}
                        border={'none'}
                        borderBottom='1px solid #E5E5E5'
                        placeholder='Write Your Message'
                        _focusVisible={
                            {
                                border: 'none',
                                borderBottom: '1px solid #E5E5E5'
                            }
                        }
                    />
                </FormControl>
                <OutlinedButton
                    rounded='0'
                    type='submit'
                    width='100%' bg={'#054E47'}
                    py='6'
                    border='none'
                    fontSize='xl'
                    color='white'>SEND MESSAGE</OutlinedButton>
            </Flex>
        </>
    );
};

export default Contact;
